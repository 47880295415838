<template id="">
  <div>
    <div class="regist">
      <h3>
        <img
          class="logo"
          src="@/img/logo.png"
          alt="インテリジェントヘルスケア ストレスチェックApp"
        />
      </h3>
      <h3 class="uk-text-background">本登録&nbsp;（1/2）</h3>
      <p>
        下記項目にご入力ください。<br />
        <em>※</em>は必須項目です
      </p>
      <div v-show="$store.state.common.sIsLoading">Loading...</div>
      <div
        v-show="
          !$store.state.common.sIsLoading && !$store.state.common.sDataLoading
        "
      >
        <div class="uk-flex uk-flex-center">
          <div class="uk-container">
            <div class="uk-text-center" uk-grid>
              <div class="uk-width-1-1">
                <div class="uk-margin">
                  <div style="text-align: left">
                    <label class="uk-form-label uk-text-muted"
                      >法人名&nbsp;<sup>※</sup></label
                    >
                  </div>
                  <input class="uk-input" type="text" v-model="com_name" />
                </div>
              </div>
              <div class="uk-width-1-1">
                <div class="uk-margin">
                  <div style="text-align: left">
                    <label class="uk-form-label uk-text-muted"
                      >フリガナ&nbsp;<sup>※</sup></label
                    >
                  </div>
                  <input class="uk-input" type="text" v-model="com_kana" />
                </div>
              </div>
              <div class="uk-width-1-1">
                <div class="uk-margin">
                  <div style="text-align: left">
                    <label class="uk-form-label uk-text-muted"
                      >代表者氏名</label
                    >
                  </div>
                  <input
                    class="uk-input"
                    type="text"
                    v-model="representative_name"
                  />
                </div>
              </div>
              <div class="uk-width-1-1">
                <div class="uk-margin">
                  <div style="text-align: left">
                    <label class="uk-form-label uk-text-muted">郵便番号</label>
                  </div>
                  <input class="uk-input" type="text" v-model="com_zip" />
                </div>
              </div>
              <div class="uk-width-1-1">
                <div class="uk-margin">
                  <div style="text-align: left">
                    <label class="uk-form-label uk-text-muted">住所</label>
                  </div>
                  <input class="uk-input" type="text" v-model="com_address" />
                </div>
              </div>
              <div class="uk-width-1-1">
                <div class="uk-margin">
                  <div style="text-align: left">
                    <label class="uk-form-label uk-text-muted">電話番号</label>
                  </div>
                  <input class="uk-input" type="text" v-model="com_tel" />
                </div>
              </div>
              <div class="uk-width-1-1">
                <div class="uk-margin">
                  <div style="text-align: left">
                    <label class="uk-form-label uk-text-muted"
                      >担当部署&nbsp;<sup>※</sup></label
                    >
                  </div>
                  <input class="uk-input" type="text" v-model="tantou_busyo" />
                </div>
              </div>
              <div class="uk-width-1-1">
                <div class="uk-margin">
                  <div style="text-align: left">
                    <label class="uk-form-label uk-text-muted"
                      >担当者氏名&nbsp;<sup>※</sup></label
                    >
                  </div>
                  <input class="uk-input" type="text" v-model="tantou_name" />
                </div>
              </div>
              <div class="uk-width-1-1">
                <div class="uk-margin">
                  <div style="text-align: left">
                    <label class="uk-form-label uk-text-muted"
                      >担当者連絡先</label
                    >
                  </div>
                  <input class="uk-input" type="text" v-model="tantou_tel" />
                </div>
              </div>
              <div class="uk-width-1-1">
                <div class="uk-margin">
                  <div style="text-align: left">
                    <label class="uk-form-label uk-text-muted"
                      >担当者メールアドレス</label
                    >
                  </div>
                  <input class="uk-input" type="text" v-model="tantou_email" />
                </div>
              </div>
              <div class="uk-flex uk-flex-center uk-width-1-1">
                <button
                  :disabled="$store.state.common.sServerProcessing"
                  class="uk-button uk-button-primary uk-width-1-3"
                  type="button"
                  @click="send_data"
                >
                  次へ
                </button>
              </div>

              <!-- *法人名：<input type="text" v-model="com_name" required/><br>
              *ﾌﾘｶﾞﾅ：<input type="text" v-model="com_kana" required/><br>
              代表者氏名：<input type="text" name="representative_name" /><br>
              郵便番号：<input type="text" v-model="com_zip" ><br>
              住所：<input type="text" v-model="com_address" ><br>
              電話番号：<input type="text" v-model=com_tel /><br>
              *担当部署：<input type="text" name=""  v-model="tantou_busyo" required/><br>
              *担当者氏名：<input type="text" name="" v-model="tantou_name" required/><br> -->
              <!-- <button type="submit" name="button" @click="send_data">次へ</button> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
export default {
  data() {
    return {
      uuid: "",
      com_name: "",
      com_kana: "",
      representative_name: "",
      com_zip: "",
      com_address: "",
      com_tel: "",
      tantou_busyo: "",
      tantou_name: "",
      tantou_tel: "",
      tantou_email: "",
      do_something: true,
    };
  },
  mounted() {
    this.$store.state.common.sIsLoading = false;
    document.title = "本登録";
  },
  created() {
    this.$store.state.common.sIsLoading = true;
    this.$store.state.common.sDataLoading = true;
    var str;
    str = this.$route.path;
    this.uuid = str.replace(/\*/, "");
    this.uuid = this.uuid.replace("/client-registration/", "");
    console.log(str);
    console.log(this.uuid);
    this.$store.state.common.sDataLoading = false;
  },
  methods: {
    send_data() {
      if (!this.do_something) return;
      this.do_something = false;
      var flg = true;
      console.log("info_send");
      if (
        this.com_name == "" ||
        this.com_kana == "" ||
        this.com_furigana == "" ||
        this.tantou_busyo == "" ||
        this.tantou_name == ""
      ) {
        flg = false;
      }
      if (flg) {
        this.$axios
          .post("/client/registration", {
            uuid: this.uuid,
            com_name: this.com_name,
            com_kana: this.com_kana,
            representative_name: this.representative_name,
            com_zip: this.com_zip,
            com_address: this.com_address,
            com_tel: this.com_tel,
            tantou_busyo: this.tantou_busyo,
            tantou_name: this.tantou_name,
            tantou_tel: this.tantou_tel,
            tantou_email: this.tantou_email,
          })
          .then(
            function (response) {
              //eslint-disable-line
              console.log(response.data);
              this.do_something = true;
              if (response.data.flg) {
                this.$store.commit("doFirstRegistration", {
                  first_registration: true,
                  alias_id: response.data.alias_id,
                });
                this.$router.push("/client-registration1/" + this.uuid);
              } else {
                if (response.data.status == 1) {
                  alert(
                    "このメールアドレスは既に登録済みです。\nログインページからログイン後、「企業情報修正」ページより修正してください"
                  );
                } else if (response.data.status == 2) {
                  alert(
                    "仮登録から1日以上経過しています。お手数ですが「新規登録」ページより再度登録し直してください"
                  );
                } else if (response.data.status == 3) {
                  alert("不正な操作です。お手数ですが再度やり直してください");
                }
                this.$router.push("/client-login");
              }
            }.bind(this)
          )
          .catch(
            function (error) {
              this.state = false;
              this.do_something = true;
              console.log(error);
            }.bind(this)
          );
      } else {
        alert("必須項目を入力してください");
        this.do_something = true;
      }
    },
  },
};
</script>
